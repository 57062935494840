<template>
  <div>
    <!-- tag标签 -->

    <!-- <el-tab-pane label="标准商品库" name="0"> -->
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 类型选择 -->
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">关键字搜索:</div> -->
            <el-input
              placeholder="商品名称"
              v-model="formApi.keyword"
            ></el-input>
          </div>
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">编号:</div> -->
            <el-input
              placeholder="商品编号"
              clearable
              type="number"
              v-model="formApi.id"
            ></el-input>
          </div>
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title exceed">批准文号:</div> -->
            <el-input
              clearable
              placeholder="批准文号"
              v-model="formApi.approvalNumber"
            ></el-input>
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">生产厂家:</div> -->
            <el-input
              placeholder="生产厂家"
              v-model="formApi.manufacturer"
            ></el-input>
          </div>
          <!-- 区域选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">商品分类:</div> -->
            <el-cascader
              v-model="cascaderValue"
              :options="BasissCopeData.inquiregoods_categoryList"
              :props="propsData"
              @change="cascaderhandleChange"
              :key="refKey"
              placeholder="请选择商品分类"
              clearable
            ></el-cascader>
          </div>
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">经营类别:</div> -->
            <el-select
              clearable
              v-model="formApi.businessScopeId"
              placeholder="请选择商品经营类别"
              filterable
            >
              <el-option
                v-for="item in BasissCopeData.Newbusiness_scope_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 搜索 -->
          <div class="sch-1 sch-2 flex1">
            <div>
              <el-button type="primary" size="mini" @click="toSearch">
                查询
              </el-button>
              <el-button type="primary" size="mini" @click="reset">
                重置查询条件
              </el-button>
            </div>
            <div class="flex-right">
              <div>
                <div class="bor nobor" @click="batchRemoval">批量禁售</div>
              </div>
            </div>
            <!-- <el-button
                    type="primary"
                    size="mini"
                    @click="getplatformGoodsexport(formApi)"
                    v-if="allJurisdiction.goodsLibrary136"
                  >
                    导出未分类商品
                  </el-button> -->
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
      <div v-if="IsLading" style="height: 100%">
        <div
          style="height: 100%"
          v-loading="IsLading"
          element-loading-text="请稍等..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 1)"
        ></div></div>
        
        <div v-if="!IsLading">
          <el-table
            :data="dataList.list"
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
            border
            @selection-change="handleSelectionChange"
            ><el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column prop="address" label="商品图片" align="center">
              <template slot-scope="{ row }">
                <div class="img-box">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="row.picture"
                    :preview-src-list="[row.picture]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="商品信息"
              align="center"
              width="320"
            >
              <template slot-scope="{ row }">
                <div class="goods-message">
                  <div class="span-box">
                    <div class="exceed">商品名称：{{ row.name || "/" }}</div>
                    <div class="exceed">商品编号：{{ row.id || "/" }}</div>
                    <div class="exceed">
                      批准文号：{{ row.approvalNumber || "/" }}
                    </div>
                    <div class="exceed">单位：{{ row.unit || "/" }}</div>
                    <!-- <div class="exceed">
                            经营类别：{{ row.type_string || "/" }}
                          </div> -->
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="经营类别" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.typeString"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.typeString || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品分类" align="center">
              <template slot-scope="{ row }">
                <el-tooltip placement="top">
                  <div slot="content">
                    <span v-for="(v, i) in row.categoryString" :key="i"
                      >{{ v }}<br
                    /></span>
                  </div>
                  <div class="exceed">
                    {{ row.categoryString[0] || "/" }}
                  </div>
                </el-tooltip>
                <!-- <el-tooltip
                        effect="dark"
                        :content="row.category_string"
                        placement="bottom"
                      >
                        <div class="exceed">
                          {{ row.category_string || "/" }}
                        </div>
                      </el-tooltip> -->
              </template>
            </el-table-column>

            <el-table-column prop="address" label="规格" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.specification }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="生产厂家" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.manufacturer"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.manufacturer }}</div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="150"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!-- <div @click="tosetting(row.id)">同步设置</div> -->
                  <div @click="toMessage(row)">详情</div>
                  <div @click="remove(row)">禁售</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          v-if="!IsLading"
          background
          @current-change="handleCurrentChange"
          :page-size="dataList.per_page"
          :current-page="formApi.currPage"
          layout="total, prev, pager, next, jumper"
          :total="dataList.total"
        >
        </el-pagination>
      </div>
    </div>

    <div class="dialog-cover" v-if="dialogVisibleRemove">
      <div class="cover-box">
        <div class="cover-title">
          商品禁售提示
          <div @click="dialogVisibleRemove = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="cover-mess">
          <span class="dialog-mess" v-if="needRemove.length > 1">
            确定要将所选
            <span>{{ needRemove.length }}</span>
            条商品加入禁售清单？禁售后与这些商品对码的供应商商品将无法上架销售，已上架的商品会自动下架，请谨慎操作。
          </span>
          <span class="dialog-mess" v-if="needRemove.length == 1">
            确定要将商品【{{
              needRemove[0].name
            }}】加入禁售清单？禁售后与该商品对码的供应商商品将无法上架销售，已上架的商品会自动下架，请谨慎操作。
          </span>
          <span class="dialog-mess" v-if="needRemove.length == 0">
            请至少选择一条需要禁售清单的商品信息。
          </span>
        </div>
        <div class="cover-foot">
          <el-button
            type="primary"
            @click="confirmRemove"
            v-if="needRemove.length > 0"
          >
            禁售
          </el-button>
          <el-button
            @click="dialogVisibleRemove = false"
            v-if="needRemove.length > 0"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            @click="dialogVisibleRemove = false"
            v-if="needRemove.length == 0"
          >
            知道了
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from "element-ui";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const Basics = createNamespacedHelpers("Basics");
const { mapState, mapActions } = createNamespacedHelpers(
  "Goods",
  "commonIndex"
);
export default {
  data() {
    return {
      dialogVisibleRemove: false,
      dialogVisibleRemoveError: false,
      multipleSelection: [],
      toLead: {},
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
      refKey: 0,
      activeName: 0,
      dataList: {},
      // 资料库请求数据
      recording: {},
      formApi: {
        businessScopeId: "",
        categoryId: "",
        keyword: "",
        manufacturer: "",
        id: "",
        currPage: 1,
        categoryId: "",
        forbidTheSaleStatus: 0,
      },
      cascaderOptions: [],
      cascaderValue: [],
      form: { val: false },
      multipleSelection: [], //列表选中
      needRemove: [], //需要移除
      IsLading: true,
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "BasissCopeData",
      "FinilsCategoryList",
      "allJurisdiction",
      "detailsSeach",
    ]),

    ...Basics.mapState(["manageData"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.formApi = this.detailsSeach[router];
      this.cascaderValue = this.detailsSeach[router].cascaderValue;
    }

    this.getplatformGoodslistApi();
    // this.cgOptions();
  },
  methods: {
    ...mapActions(["postgoodsList", "postupdateForbidTheSaleStatus"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // closedialogVisibleRemove() {
    //   this.dialogVisibleRemove = false;
    // },

    async getplatformGoodslistApi() {
      let data = await this.postgoodsList(this.formApi);
      if ((data.code = "000000")) {
        data.content.list.forEach((v) => {
          let categoryArr = [];
          v.categoryStringList.forEach((v1) => {
            // console.log(v1)
            let category = "";
            if (v1.length > 0) {
              v1.forEach((v2, i2) => {
                if (i2 == v1.length || i2 == 0) {
                  category = category + v2;
                } else {
                  category = category + ">" + v2;
                }
              });
            }
            categoryArr.push(category);
          });
          v.businessCount = v.businessCount + "";
          v.categoryString = categoryArr;
        });
        this.dataList = data.content;
        if (this.formApi.currPage > 1 && this.dataList.list.length == 0) {
          this.formApi.currPage--;
          this.getplatformGoodslistApi();
        }
      }
      this.IsLading=false
    },
    async handleCurrentChange(e) {
      this.formApi.currPage = e;
      this.getplatformGoodslistApi();
    },
    async toSearch() {
      this.formApi.currPage = 1;
      this.getplatformGoodslistApi();
    },
    reset() {
      this.formApi = {
        businessScopeId: "",
        categoryId: "",
        keyword: "",
        manufacturer: "",
        id: "",
        currPage: 1,
        forbidTheSaleStatus: 0,
      };
      this.cascaderValue = [];
      ++this.refKey;
      this.toSearch();
    },
    cascaderhandleChange(value) {
      this.cascaderValue = value;
      this.formApi.categoryId = value[value.length - 1];
    },
    // 确定移除
    async confirmRemove() {
      let operatorId = sessionStorage.getItem("admin_id");
      let Arr = [];
      this.needRemove.forEach((item) => {
        let obj = {
          forbidTheSaleStatus: 1,
          operatorId,
          id: item.id,
        };
        Arr.push(obj);
      });
      let data = await this.postupdateForbidTheSaleStatus(Arr);
      if (data.code == "000000") {
        this.$message({
          message: "所选商品已成功加入禁售清单。",
          type: "success",
        });
        this.dialogVisibleRemove = false;
        this.getplatformGoodslistApi();
      }
    },
    // 批量移除
    batchRemoval() {
      this.needRemove = this.multipleSelection;
      this.dialogVisibleRemove = true;
      // if (this.multipleSelection.length > 0) {
      //   this.needRemove = this.multipleSelection;
      //   this.dialogVisibleRemove = true;
      // } else {
      //   this.dialogVisibleRemoveError = true;
      // }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 移除禁售清单
    remove(val) {
      this.needRemove = [];
      this.needRemove.push(val);
      this.dialogVisibleRemove = true;
      // console.log(val);
    },
    // 调整次级联动结构格式
    cgOptions() {
      setTimeout((v) => {
        this.cascaderOptions = categoryList(this.FinilsCategoryList);
      }, 1000);
    },
    // 详情
    toMessage(row) {
      this.$router.push({
        path: "/goodMessage",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.merch-card {
  width: 100%;
  max-height: 750px;
  box-shadow: none;
  padding-top: 0;
  .merch-tag {
    width: 100%;
    height: 40px;
  }
  // 搜索行样式
  .merch-search {
    margin-top: 1%;
    width: 100%;
    .search-box {
      display: flex;
      flex-wrap: wrap;
    }
    .sch-1 {
      margin-top: 10px;
      width: 18%;
      min-width: 200px;
      display: flex;

      .sch-title {
        width: 40%;
        min-width: 80px;
        height: 100%;
        font-size: 13px;
        background-color: #f5f7fa;
        color: #909399;
        border: 1px solid #dcdfe6;
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 5px !important;
      }
    }
    .sch-2 {
      margin-right: 2%;
    }
  }
  .merch-table {
    margin-top: 1%;
    width: 100%;
  }
  .merch-page {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
  }
}
.goods-message {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img-box {
    width: 50px;
    height: 50px;
    background: rebeccapurple;
  }
  .span-box {
    margin-left: 10px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    div {
      width: 100%;
      text-align: left;
    }
  }
}
.flex {
  display: flex;
  .el-form-item {
    margin-bottom: 0;
    width: 200px;
  }
}
::v-deep .el-dialog {
  width: 600px;
}
::v-deep .el-form-item__label {
  width: 120px;
}
::v-deep .el-input__inner {
  line-height: 38px;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 107px;
}
.flex1 {
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: space-between;
  min-width: 800px !important;
  .flex-right {
    background-color: #06b7ae;
  }
  > div {
    height: 40px;
    display: flex;
    font-size: 14px;
    border-radius: 4px;
    // background-color: #06b7ae;
    color: #ffffff;

    > div {
      // padding: 0 10px;
      // margin-top: 8px;
      // height: 24px;
      // line-height: 24px;
      // border-right: 1px solid #fff;
      // margin-top: 12px;
      // margin-bottom: 12px;
      cursor: pointer;
      width: 120px;
      text-align: center;
      box-sizing: border-box;
      .bor {
        cursor: pointer;
        height: 16px;
        line-height: 16px;
        border-right: 1px solid #fff;
        margin-top: 12px;
      }
      .nobor {
        border-right: none;
      }
      .schedule {
        position: relative;
        > div {
          height: 40px;
          line-height: 40px;
        }
        .all {
          background-color: #f2f2f2;
          color: #909399;
          width: 120px;
        }
        .complete {
          position: absolute;
          z-index: 2;
          background-color: #06b7ae;
          top: 0;
          width: 80px;
          white-space: nowrap;
          overflow: hidden;
          > div {
            width: 120px;
          }
        }
      }
    }
    // .noborder{
    //   border-right: none;
    // }
  }
  .el-button {
    height: 40px;
    font-size: 14px;
  }
}
.dialog-mess {
  line-height: 24px;
  span {
    color: #06b7ae;
  }
}
.popconfirm-button {
  border: none;
  padding: 0;
  img {
    width: 50px;
    height: 20px;
  }
}
::v-deep .el-date-editor {
  width: 100%;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 50px !important;
}
.dialog-cover {
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.2);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
}
.cover-box {
  background-color: #fff;
  width: 500px;
  border-radius: 4px;
  height: 160px;
  margin-top: 10%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  .cover-title {
    font-size: 18px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    .el-icon-close {
      font-size: 24px;
    }
  }
  .cover-mess {
    padding: 10px;
    font-size: 14px;
  }
  .cover-foot {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100%;
  }
}
</style>
